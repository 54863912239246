<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <el-container>
          <!-- 左侧大模块 -->
          <el-main class="main_left">
            <div class="main_title">报装工单信息</div>
            <div class="form">
              <span class="img"></span>
              <span class="title">申请信息</span>
              <el-form ref="addform" :model="form"  size="mini" class="form_user">
                <el-form-item label="用气类型:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="法人姓名:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="法人身份证号:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="联系人电话:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="公司名称:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="公司地址:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="用途:" prop="positionName">
                 {{form.name}}
                </el-form-item>
              </el-form>
              <span class="img"></span>
              <span class="title">工单信息</span>
              <el-form ref="addform" :model="form"  size="mini" class="form_user">
                <el-form-item label="工单号:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="用户类型:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="用气设备:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="执行位置:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="测量员:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="工单状态:" prop="positionName">
                 {{form.name}}
                </el-form-item>
                <el-form-item label="执行简图:" prop="positionName">
                 {{form.name}}
                </el-form-item>
              </el-form>
            </div>
          </el-main>
          <!-- 右侧大模块 -->
          <el-main class="main_right">
            <div class="main_title">报装进程信息</div>
            <div class="block">
              <el-timeline :reverse="reverse">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  color='#077DFF'
                  >
                  <div class="order">                                                                                                                                                                                                                                                                                                                                                                                                    
                    <span class="order_title">
                      {{activity.content}}
                    </span>
                    <span class="order_desc">
                      <span style="margin-left:40px">
                        创建人:{{activity.user}}
                      </span>
                      <span style="margin-left:100px">
                        创建时间:{{activity.timestamp}}
                      </span>
                      <el-button type="primary" plain size="mini" class="prder_button">查看</el-button>
                    </span>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'selectDesc',
  data() {
    return {
      form:{},
      reverse: false,
      activities: [{
        content: '工单创建',
        timestamp: '2022-03-06',
        user:'小彬',
      },
      {
        content: '工单审批',
        timestamp: '2022-03-07',
        user:'阿彬',
      },
      {
        content: '初步设计',
        timestamp: '2022-03-08',
        user:'彬彬',
      }
      ]
    }
  },
  mounted() {
 
  },
  methods: {

  }
}
</script>

<style  lang="less" scoped>
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.main_title{//大板块的总标题
  border-bottom: 1px solid #A5A8AC;
  font-size: 20px;
  margin-bottom: 5px;
}
.main_left{
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
}
.main_right{
  width: 33.3%;
  margin-right: 20px;
  background-color: white;
  border-radius: 5px;
}
.img {
  border: 1px solid #3f74d5;
  margin-right: 2px;
}
.form_user{
  margin: 10px;
}
.title{
  font-size: 18px;
}
.form{
  margin: 10px;
}
.block{
  margin:10px;
}
.order{
  width: 940px;
}
.order_title{
  font-size: 20px;
  margin-left: 30px;
  color: #1173E2;
}
.order_desc{
  margin-left: 60px;
  font-size: 20px;
}
.prder_button{
  margin-left:360px;
  margin-bottom: 2px;
}
</style>
